import {
  JobMatcherJobsFilterEnum,
  JobOrderByColumn,
  JobSourceType,
  QueryCurrentTalentJobBoardSearchArgs,
  SortOrder,
  TalentCompanyPoolConnection,
} from '@libs/graphql-types';
import { isNil } from '@libs/helpers/common';
import { OptionType } from '@libs/ui/components/form/select';

import { FilterForm, JobTabType, ViewTypes } from './types';

const mapOptionsToQuery = (opts: OptionType[]) =>
  opts.map(({ value, text, skill_type }) => {
    const arr = [value, text];
    return skill_type ? arr.concat(skill_type) : arr;
  });

export const mapFormToQuery = (form: FilterForm) => {
  return {
    ...form,
    externalJobSeniority: mapOptionsToQuery(form.externalJobSeniority || []),
    externalJobEmployment: mapOptionsToQuery(form.externalJobEmployment || []),
    externalCompanyName: mapOptionsToQuery(
      !!form.externalCompanyName ? [form.externalCompanyName] : [],
    ),
    categories: mapOptionsToQuery(form.categories || []),
    skills: mapOptionsToQuery(form.skills || []),
    companies: Object.entries(form.companies || {})
      .filter(([, v]) => v)
      .map(([k]) => k),
  };
};

const mapOptionsFromQuery = (str: string) =>
  str?.split(';').map((s) => {
    const [value, text, skill_type] = s?.split(',');
    return { value, text, ...(skill_type && { skill_type }) };
  });

export const mapQueryToVariables = ({
  query,
  currentView,
  connections,
}: {
  query: Record<string, any>;
  currentView: ViewTypes;
  connections: TalentCompanyPoolConnection[];
}): QueryCurrentTalentJobBoardSearchArgs => {
  const values = mapQueryToInitialValues(query, { connections });
  const isMatcherView = currentView === ViewTypes.Matcher;
  const isTalentView = currentView === ViewTypes.Talent;
  const isOpenJobsTab = values.tab === JobTabType.Open;
  const isClosedJobsTab = values.tab === JobTabType.Closed;
  const isApplicationsTab = values.tab === JobTabType.MyApplications;

  return {
    ...(isOpenJobsTab && {
      is_active: true,
    }),

    ...(isClosedJobsTab && {
      is_active: false,
    }),

    ...(isMatcherView && {
      // company_id: OPENTALENT_COMPANY_ID,
      job_source_type: [JobSourceType.Internal],
      ...(isApplicationsTab && {
        job_matcher_jobs_filter: JobMatcherJobsFilterEnum.IsAppliedOrAssigned,
        is_assigned_as_matcher: true,
        is_applied_as_matcher: true,
      }),
      ...(isOpenJobsTab && {
        job_matcher_jobs_filter:
          JobMatcherJobsFilterEnum.AllAvailableForMatchersJobs,
      }),
    }),

    ...(isTalentView && {
      is_saved: values.is_saved,

      communities_filter: {
        company_ids:
          Object.entries(values.companies || {})
            .filter(([, v]) => v)
            .map(([k]) => k) || [],

        include_external_jobs: !!values.jobSourceExternal,
      },
      country: values.country,

      ...(isApplicationsTab && {
        is_applied: true,
      }),
    }),

    search: values.search || undefined,
    categories: values.categories?.map((i) => `${i.value}`) || undefined,
    skills: values.skills?.map((i) => `${i.value}`) || undefined,
    rate_min: (values as any).rate_min || undefined,
    rate_max: (values as any).rate_max || undefined,
    page: Number(values.page) || 1,
    first: 20,
    ...(values?.order_by_column
      ? {
          order_by: [
            {
              column: values.order_by_column as JobOrderByColumn,
              order: SortOrder.Desc,
            },
          ],
        }
      : {
          order_by: [
            {
              column: JobOrderByColumn.CreatedAt,
              order: SortOrder.Desc,
            },
          ],
        }),
  };
};

export const mapQueryToInitialValues = (
  query: Record<string, any>,
  {
    connections,
  }: {
    connections?: TalentCompanyPoolConnection[];
  } = {},
): Partial<FilterForm> => {
  const companies = isNil(query.companies) ? '' : String(query.companies);
  const defaultCompanies = Object.fromEntries(
    connections?.map((i) => [i.company?.id, true]) || [],
  );

  return {
    companies: !!companies
      ? Object.fromEntries(companies.split(';').map((i: string) => [i, true]))
      : defaultCompanies,
    externalJobSeniority: mapOptionsFromQuery(query?.externalJobSeniority),
    externalJobEmployment: mapOptionsFromQuery(query?.externalJobEmployment),
    externalCompanyName: !!query?.externalCompanyName
      ? mapOptionsFromQuery(query?.externalCompanyName)[0]
      : undefined,
    country: query?.country,

    categories: mapOptionsFromQuery(query?.categories),
    skills: mapOptionsFromQuery(query?.skills),
    search: query.search,
    rate_max: query.rate_max,
    rate_min: query.rate_min,
    order_by_column: query.order_by_column
      ? query.order_by_column
      : JobOrderByColumn.CreatedAt,
    is_saved: query.is_saved,
    page: query.page,
    is_active: query.is_active,

    tab: query?.tab || JobTabType.Open,

    jobSourceInternal:
      query.jobSourceInternal === undefined ? true : !!query.jobSourceInternal,
    jobSourceExternal:
      query.jobSourceExternal === undefined ? true : !!query.jobSourceExternal,
  };
};
