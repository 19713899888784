import { gql } from '@apollo/client';
import FULL_JOB_MATCH_FRAGMENT from 'graphql/fragments/companyUser/jobMatchFragment';

export default gql`
  ${FULL_JOB_MATCH_FRAGMENT}
  fragment FullJob on Job {
    id
    stream_chat_subject_id
    name
    country
    city
    propose_to_matchers
    is_draft
    is_remote_an_option
    link_to_details
    office_hours_per_month
    skills {
      id
      name
      skill_type
      job_skill_pivot {
        is_required
      }
    }
    skills_boolean_filter {
      not_skills {
        id
        name
        skill_type
      }
      boolean_skills_filter_items {
        one_of_skills {
          id
          name
          skill_type
        }
        all_of_skills {
          id
          name
          skill_type
        }
        boolean_operator
      }
    }
    category {
      id
      name
    }
    subcategory {
      id
      name
    }
    description
    pitch
    start_date
    end_date
    rate_min
    rate_max
    is_rate_negotiable
    campaign_owner {
      id
      first_name
      last_name
      email
      company {
        name
        id
      }
    }
    invitations {
      id
      talent_id
    }
    campaign_start_date
    campaign_end_date
    campaign_talent_pool
    is_archived
    matches {
      ...FullJobMatch
    }
    matches_count
    instant_matches_count
    matches_with_application_count
    salary_max
    salary_min
    finders_fee
    saved_matches {
      created_at
      id
      talent {
        first_name
        last_name
        id
        email
        address
        recent_position_title
        location
        rate_max
        rate_min
        is_invitation_accepted
      }
      matched_skills {
        id
        name
        skill_type
      }
      match_percent
      match_type
    }
    location
    hours_per_week
    location_type
    type
    period
    client
    capacity
    posted_at
  }
`;
