import React, { useState } from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

import { AccordionItem } from './AccordionItem';
import { FAQ_ITEMS } from './consts';

interface FAQProps {}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.secondary.dark,
    },

    [theme.breakpoints.down('md')]: {
      padding: '40px 24px 48px',
    },
  },
  blockTitle: {
    lineHeight: '40px',
    fontWeight: 700,
    fontStyle: 'italic',
    marginBottom: theme.spacing(15),
  },
}));

export const FAQ = (props: FAQProps) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState<number>();

  return (
    <Box className={classes.wrapper}>
      <Box mb={8}>
        <Typography variant="h6" textAlign="center" fontWeight={500}>
          Your Path to Career Advancement
        </Typography>
      </Box>

      <Box maxWidth={600} style={{ margin: '0 auto' }}>
        {FAQ_ITEMS.map((i, index) => (
          <AccordionItem
            {...i}
            key={i.title}
            isActive={index === activeIndex}
            onChange={() =>
              setActiveIndex(index === activeIndex ? undefined : index)
            }
          />
        ))}
      </Box>
    </Box>
  );
};
