import { REJECTION_REASONS } from 'consts/common';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import {
  DefaultModalProps,
  useOpenModal,
  withLocationStateModal,
} from 'utils/modals';
import * as yup from 'yup';

import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  JobMatch,
  SuggestingStageEnum,
  useChangeSuggestingStatusForJobMatchMutation,
} from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import Button from '@libs/ui/components/button';
import { ConnectedSelect } from '@libs/ui/components/form/select';
import { DefaultModal } from '@libs/ui/components/modals';

interface ModalData {
  rejectStatus: SuggestingStageEnum;
  match: JobMatch;
}

interface LimitedProfileAccessModalProps extends DefaultModalProps<ModalData> {
  onSuccess?: () => void;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '585px',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
}));

type FormModel = {
  message: string;
};

const validator = yup.object().shape({
  message: yup.string().required('Required').min(1),
});

const RejectSubmittedTalentModalComponent = ({
  modalData,
  isOpen,
  close,
  onSuccess,
}: LimitedProfileAccessModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [reject, { loading: isRejecting }] =
    useChangeSuggestingStatusForJobMatchMutation({
      variables: {
        job_match_id: modalData?.match?.id!,
        new_suggesting_stage: modalData?.rejectStatus!,
      },
      onCompleted: () => {
        enqueueSnackbar('Talent application has been rejected.', {
          variant: 'success',
        });
        onSuccess?.();
        close();
      },
    });

  return (
    <Formik<FormModel>
      initialValues={{ message: '' }}
      validationSchema={validator}
      onSubmit={(values) => {
        reject({
          variables: {
            job_match_id: modalData?.match?.id!,
            new_suggesting_stage: modalData?.rejectStatus!,
            message: values.message,
          },
        });
      }}
    >
      {({ submitForm }) => {
        return (
          <DefaultModal
            handleClose={close}
            open={isOpen}
            className={classes.modal}
            title="Rejecting talent"
            actions={
              <Grid spacing={4} container>
                <Grid xs={6} item>
                  <Button
                    fullWidth
                    size="large"
                    color="error"
                    variant="contained"
                    disabled={isRejecting}
                    onClick={submitForm}
                  >
                    Reject talent
                  </Button>
                </Grid>
                <Grid xs={6} item>
                  <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    color="info"
                    onClick={close}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            }
          >
            <Box mt={4} mb={8}>
              <Typography variant="body1">
                Are you sure you want to reject this talent?
              </Typography>
            </Box>

            <Box style={{ textAlign: 'left' }}>
              <ConnectedSelect
                name={modelPath<FormModel>((m) => m.message)}
                hideNoneValue
                fullWidth
                options={REJECTION_REASONS}
                variant="filled"
                label="Reason"
                size="small"
              />
            </Box>
          </DefaultModal>
        );
      }}
    </Formik>
  );
};
RejectSubmittedTalentModalComponent.id = 'RejectTalentModal';

export const RejectSubmittedTalentModal = withLocationStateModal<ModalData>({
  id: RejectSubmittedTalentModalComponent.id,
  getModalKey: (data) => {
    console.log(data);
    return data?.match?.id;
  },
})(RejectSubmittedTalentModalComponent);

export const useOpenRejectSubmittedTalentModal = () =>
  useOpenModal(RejectSubmittedTalentModalComponent.id);
