import { ConnectedPageLayout } from 'components/layout/page-layout';
import React from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { About } from './about';
import { FAQ } from './faq';
import { HowItWorks } from './how-it-works';
import { Intro } from './intro';

interface CareerHubProps {}

const useStyles = makeStyles(() => ({
  contentWrapper: {},
}));

export const CareerHub = (props: CareerHubProps) => {
  const classes = useStyles();

  return (
    <div>
      <ConnectedPageLayout
        classes={{ contentWrapper: classes.contentWrapper }}
        documentTitle="Career Hub"
        headerProps={{ accountProps: {} }}
        drawerProps={{}}
      >
        <Intro />
        <About />
        <HowItWorks />
        <Box mt={18}>
          <FAQ />
        </Box>
      </ConnectedPageLayout>
    </div>
  );
};
