import { useCurrentUser } from 'hooks/auth';

import { CompanyAccountTypeEnum } from '@libs/graphql-types';

export const useCompanyAccountType = () => {
  const { data } = useCurrentUser();

  return (
    data?.currentCompanyUser?.company?.account_type ||
    CompanyAccountTypeEnum.Free
  );
};

export const useCompanyAccountTypeCheck = (types: CompanyAccountTypeEnum[]) => {
  const currentAccountType = useCompanyAccountType();
  const res = types.includes(currentAccountType);

  return res;
};

export const useIsFreeCompanyAccount = () =>
  useCompanyAccountTypeCheck([
    CompanyAccountTypeEnum.Free,
    CompanyAccountTypeEnum.None,
  ]);

export const useIsEnterpriseCompanyAccount = () =>
  useCompanyAccountTypeCheck([CompanyAccountTypeEnum.Enterprise]);

export const useIsPrimaryUserAccount = () => {
  const { data } = useCurrentUser();

  return !!data?.currentCompanyUser?.is_primary;
};
