import { gql } from '@apollo/client';

export default gql`
  mutation CreateUntrustedTalentProfile(
    $email: Email!
    $general_info: TalentGeneralInfoInput!
    $source_company_id: ID
    $job_application_data: JobApplicationInput
    $origin: TalentOriginEnum
    $invitation_uid: String
    $tags: [ID]
    $recruiter_info: RecruiterInfoInput
  ) {
    createUntrustedTalentProfile(
      email: $email
      origin: $origin
      job_application_data: $job_application_data
      general_info: $general_info
      source_company_id: $source_company_id
      invitation_uid: $invitation_uid
      tags: $tags
      recruiter_info: $recruiter_info
    )
  }
`;
