import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

export interface AccordionProps {
  title: string;
  text: React.ReactNode;

  isActive: boolean;
  onChange: VoidFunction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: `${theme.spacing(4)} !important`,
    overflow: 'hidden',
    marginBottom: `${theme.spacing(2)} !important`,
  },

  details: {
    background: theme.palette.grey[100],
    padding: theme.spacing(4),
    textAlign: 'left',
  },

  summaryContent: {
    margin: `${theme.spacing(5, 2)} !important`,
    textAlign: 'left',
  },
}));

export const AccordionItem = ({
  title,
  text,
  isActive,
  onChange,
}: AccordionProps) => {
  const classes = useStyles();

  return (
    <Accordion
      classes={{ root: classes.root }}
      elevation={0}
      expanded={isActive}
      onChange={onChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{ content: classes.summaryContent }}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.details }}>
        <Typography variant="body2">{text}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
