import React from 'react';
import { usePDF } from 'react-to-pdf';
import {
  DefaultModalProps,
  useOpenModal,
  withLocationStateModal,
} from 'utils/modals';

import { Box, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Job } from '@libs/graphql-types';
import { sleep } from '@libs/helpers/common';
import { DefaultModal } from '@libs/ui/components/modals';
import Typography from '@libs/ui/components/typography';

import DataGrid from '../job-info/DataGrid';
import { mapJobToDataGridItems } from './utils';

interface LobbyPaidPopupProps extends DefaultModalProps<true> {
  job: Job;
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 700,
    textAlign: 'left',
    padding: theme.spacing(4, 0),
  },
  description: {
    color: 'black !important',

    '& *': {
      color: 'black !important',
    },
    '& > p': {
      margin: 0,
    },
  },
}));

const JobPDFPopupComponent = ({ isOpen, close, job }: LobbyPaidPopupProps) => {
  const classes = useStyles();
  const [isUploading, setIsUploading] = React.useState(false);
  const { toPDF, targetRef } = usePDF({ filename: `${job?.name}.pdf` });

  return (
    <DefaultModal
      handleClose={close}
      open={isOpen}
      className={classes.root}
      title={
        <Box>
          <Typography variant="h5" textAlign="center" fontWeight={400}>
            Job details
          </Typography>
        </Box>
      }
      actions={
        <Grid container px={8}>
          <Grid item flexGrow={1}>
            <Button
              variant="contained"
              color="info"
              fullWidth
              disabled={isUploading}
              onClick={async () => {
                setIsUploading(true);
                toPDF();
                await sleep(30 * 1000);
                setIsUploading(false);
              }}
            >
              Download
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box ref={targetRef} px={8} pt={8}>
        <Typography variant="h6" paragraph textAlign="center" fontWeight="bold">
          {job?.name}
        </Typography>
        <DataGrid items={mapJobToDataGridItems(job as any)} />
        <Box pb={4} width="100%" mt={8}>
          <div
            className={classes.description}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: job?.description || '' }}
          />
        </Box>
      </Box>
    </DefaultModal>
  );
};

const MODAL_KEY = 'job-pdf-popup';

export const JobPDFPopup = withLocationStateModal({
  id: MODAL_KEY,
})(JobPDFPopupComponent);

export const useOpenJobPDFModal = () => useOpenModal(MODAL_KEY);
