import { useField } from 'formik';
import React, { useCallback } from 'react';

import wrapControlWithGraph from '@libs/ui/components/form/hocs/wrapControlWithGraph';

import Select, { SelectProps } from './Select';

export type ConnectedSelectProps = {
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & SelectProps;

const ConnectedSelect = (props: ConnectedSelectProps) => {
  const { name, helperText, onChange, ...rest } = props;
  const [field, meta] = useField(name);

  const handleChange = useCallback(
    (e: any) => {
      field.onChange(e);
      if (onChange) onChange(e);
    },
    [onChange, field],
  );

  const error = meta.touched && !!meta.error;
  const finalHelperText = meta.touched && meta.error ? meta.error : helperText;
  const fieldValue = field.value;

  return (
    <Select
      helperText={finalHelperText}
      {...rest}
      {...field}
      value={fieldValue}
      onChange={handleChange}
      error={error}
    />
  );
};

export default ConnectedSelect;
export const ConnectedGraphSelect =
  wrapControlWithGraph<ConnectedSelectProps>(ConnectedSelect);
