export const noop = (...params: any) => undefined;

export const sleep = (seconds: number) =>
  new Promise((res) => setTimeout(res, seconds));

export const stopEvent = (e: React.UIEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

export const uniq = (arr: any[]) => [...new Set(arr)];

export const isNumber = (num: any): num is number =>
  num !== '' && !Number.isNaN(+num) && Number.isFinite(+num) && !isNil(num);

export const parseNumber = (num: any): number | undefined =>
  isNumber(num) ? +num : undefined;

const nilTypes = [undefined, null];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNil = (arg: any): arg is typeof nilTypes[number] =>
  nilTypes.includes(arg);

export const hasNumbersInString = (str?: string) => {
  if (!str) {
    return false;
  }

  return !!str?.replace(/\D/g, '');
};

export const getRanges = (start: number, end: number, step = 500) => {
  const ranges = [];

  for (let i = start; i <= end; i += step) {
    ranges.push(i);
  }

  return ranges;
};

export function getDecimalPart(num: number): string {
  const numStr = num.toFixed(2);
  const decimalPart = numStr.substring(numStr.indexOf('.'));

  return decimalPart;
}
