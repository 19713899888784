import React from 'react';
import { pathManager } from 'routes';

import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { RouterButton } from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

import img1 from './icons/1@2x.png';
import img2 from './icons/2@2x.png';
import img3 from './icons/3@2x.png';

interface HowItWorksProps {}

const CARD_ITEMS = [
  {
    text: 'Create Your Profile',
    icon: img1,
  },
  {
    text: 'Customize Your Goals',
    icon: img2,
  },
  {
    text: 'Access Powerful Tools',
    icon: img3,
  },
];

const useStyles = makeStyles(() => ({
  card: {
    padding: '24px !important',
    background: 'white',
    maxWidth: 670,
    margin: '0 auto',
    borderRadius: 16,
    marginTop: 12,
  },
}));

export const HowItWorks = (props: HowItWorksProps) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container flexDirection="column" spacing={4}>
        <Grid item>
          <Typography
            variant="overline"
            color="info.main"
            transform="uppercase"
            textAlign="center"
            component="div"
          >
            How it works
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" textAlign="center" fontWeight={500}>
            Your Path to Career Advancement
          </Typography>
        </Grid>
        <Grid item>
          <Box className={classes.card}>
            <Grid item container justifyContent="space-between">
              {CARD_ITEMS.map((i) => (
                <Grid item key={i.text}>
                  <Grid container flexDirection="column" spacing={4}>
                    <Grid item container justifyContent="center">
                      <img srcSet={`${i.icon} 2x`} alt={i.text} />
                    </Grid>
                    <Grid item>
                      <Typography>{i.text}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid container mt={6} justifyContent="center">
              <Grid item>
                <RouterButton
                  variant="contained"
                  color="info"
                  to={pathManager.talent.careerHub.pricing.generatePath()}
                >
                  Supercharge your job search now
                </RouterButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
