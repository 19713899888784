import React from 'react';

import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

import { SECTIONS_CONTENT } from './consts';
import { AboutSection } from './section';

interface AboutProps {}

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    maxWidth: 1030,
    margin: '0 auto',
    padding: theme.spacing(18, 4, 8),
  },
}));

export const About = (props: AboutProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Grid container>
          {SECTIONS_CONTENT.map((i) => {
            return (
              <Grid item key={i.title} mb={20}>
                <AboutSection
                  title={i.title}
                  text={i.text}
                  img={i.img}
                  isReversed={i.isReversed}
                  subtitle={i.subtitle}
                  bullets={i.bullets}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};
