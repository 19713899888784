import React from 'react';

import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useMediaQueries } from '@libs/helpers/hooks/media-queries';
import Typography from '@libs/ui/components/typography';

export interface AboutSectionProps {
  text: React.ReactNode;
  img: string;
  title: string;
  subtitle: string;
  bullets: string[];
  isReversed?: boolean;
}

const useStyles = makeStyles((theme) => ({
  bulletIcon: {
    width: 6,
    height: 6,
    display: 'block',
    background: theme.palette.success.main,
  },
}));

export const AboutSection = ({
  text,
  img,
  title,
  isReversed,
  subtitle,
  bullets,
}: AboutSectionProps) => {
  const { isSM } = useMediaQueries();
  const classes = useStyles();

  return (
    <Grid
      container
      flexDirection={isSM ? 'column' : isReversed ? 'row-reverse' : 'row'}
      flexWrap="nowrap"
      spacing={8}
    >
      <Grid item container>
        <Grid item>
          <Box mb={4}>
            <Typography
              variant="overline"
              transform="uppercase"
              color="info.main"
            >
              {subtitle}
            </Typography>
          </Box>
          <Typography variant="h6" paragraph fontWeight={500}>
            {title}
          </Typography>
          <Typography paragraph variant="body2">
            {text}
          </Typography>

          {bullets.map((i) => (
            <Grid container key={`${i}${title}`} flexWrap="nowrap" spacing={2}>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <i className={classes.bulletIcon} />
              </Grid>
              <Grid item>
                <Typography variant="body2">{i}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" alignItems="center">
        <img
          srcSet={`${img} 2x`}
          alt="what"
          style={{ width: 400, display: 'block', height: 240 }}
        />
      </Grid>
    </Grid>
  );
};
