import defaultAvatarPath from 'assets/images/default-avatar.svg';

import { SkillTypeEnum } from '@libs/graphql-types';
import { OptionType } from '@libs/ui/components/form/select';

export const DEFAULT_AVATAR = defaultAvatarPath;

export const SKILL_TYPES_MAP = {
  [SkillTypeEnum.HardSkills]: 'Hard Skills',
  [SkillTypeEnum.Solutions]: 'Solutions',
  [SkillTypeEnum.SoftSkills]: 'Soft Skills',
};
export const INFINITY_SIGN = '∞';

export const OPENTALENT_COMPANY_ID = '1';

export const REJECTION_REASONS: OptionType[] = [
  `Does Not Meet Requirements - Experience`,
  `Does Not Meet Requirements - Skills`,
  `Does Not Meet Requirements - Qualifications`,
  `Does Not Meet Requirements - Salary`,
  `Does Not Meet Requirements - Visa`,
  `Position Closed`,
  `Position Filled`,
  'Other candidates are better match',
  `Duplication of Candidate`,
  `Candidate Withdrew Application`,
  `Cultural Fit Concerns or other`,
].map((i) => ({ value: i, text: i }));
