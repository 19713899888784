import { gql } from '@apollo/client';
import FULL_TALENT_FRAGMENT from 'graphql/fragments/talent/fullTalentFragment';

export default gql`
  ${FULL_TALENT_FRAGMENT}
  fragment FullJobMatch on JobMatch {
    created_at
    job_invitation {
      is_declined
    }
    job_application {
      pitch
    }
    cv_skills_keywords {
      id
      skill_type
      name
    }
    id
    job_match_declination {
      id
      reason
      message
    }
    is_talent_notified
    talent {
      ...FullTalent
    }
    matched_skills {
      id
      name
      skill_type
    }
    match_percent
    match_type
    is_instant_match
    is_applied
    is_invited
    is_shortlist
    match_quality
    is_new
    suggesting_stage
    job_suggestion {
      is_suggested_by_matcher
      suggested_by_talent {
        first_name
        last_name
        first_name_abac {
          value
        }
        last_name_abac {
          value
        }
      }
      screening_questionnaire_attachment {
        title
        url
      }
      motivation_letter_attachment {
        title
        url
      }
      cv_attachment {
        title
        url
      }
      first_name
      last_name
      talent {
        id
        first_name_abac {
          value
        }
        last_name_abac {
          value
        }
        avatar {
          avatar
        }
      }
    }
  }
`;
