import { gql } from '@apollo/client';
import FULL_USER_FRAGMENT from 'graphql/fragments/companyUser/fullCompanyUserFrag';

export default gql`
  ${FULL_USER_FRAGMENT}
  fragment FullJob on Job {
    id
    name
    country
    city
    is_saved
    office_hours_per_month
    job_source_type

    is_applied
    link_to_details
    salary_max
    salary_min
    finders_fee
    skills {
      id
      name
      skill_type
      job_skill_pivot {
        is_required
      }
    }
    category {
      id
      name
    }
    description
    pitch
    start_date
    end_date
    rate_min
    rate_max
    is_rate_negotiable
    campaign_owner {
      ...FullUser
    }
    invitations {
      id
      talent_id
    }
    campaign_start_date
    campaign_end_date
    campaign_talent_pool
    is_archived
    matches_count
    instant_matches_count
    matches_with_application_count
    saved_matches {
      created_at
      id
      talent {
        first_name
        last_name
        id
        email
        address
        recent_position_title
        location
        rate_max
        rate_min
        is_invitation_accepted
      }
      matched_skills {
        id
        name
        skill_type
      }
      match_percent
      match_type
    }
    matchers {
      id
    }
    location
    hours_per_week
    location_type
    type
    period
    client
    capacity
    posted_at
  }
`;
