import cn from 'classnames';
import { CheckThickListItem } from 'components/check-thick-list';
import React from 'react';

import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getDecimalPart } from '@libs/helpers/common';
import { formatCurrency2 } from '@libs/helpers/format';
import Button from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

import stripe from './stripe@2x.png';

interface MatcherPlanCardProps extends MatcherPlanData {}

export interface MatcherPlanData {
  title: string;
  text: string;
  subtitle: string;
  points: string[];
  additionalPoints?: string[];
  price?: number;
  isBig?: boolean;
  chipText?: string;
  buttonText?: string;
  paymentLink: string;
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 16,
    display: 'flex',
    boxShadow: '0px 10px 14px 1px rgba(0, 0, 0, 0.14)',
    flexDirection: 'column',
    minHeight: '100%',
    overflow: 'hidden',
    background: 'white',
    width: 400,
  },
  cardBig: {
    width: 430,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(6, 6),
    flexGrow: 1,
  },
  title: {
    backgroundColor: 'rgba(245, 245, 245, 1)',
    width: '100%',
    padding: theme.spacing(10),
    textAlign: 'center',
    position: 'relative',
  },
  chip: {
    position: 'absolute',
    right: 0,
    top: 18,
    fontSize: 12,
    padding: theme.spacing(0, 2),
    background: theme.palette.success.dark,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  textBlockDark: {
    backgroundColor: '#EEEEEE',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  borderedBlock: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    padding: theme.spacing(2, 4),

    '& > &': {
      letterSpacing: -0.1,
    },
  },
}));

export const CareerHubPlanCard = ({
  title,
  subtitle,
  points,
  additionalPoints,
  buttonText = 'next',
  paymentLink,
  price,
  isBig = false,
  text,
}: MatcherPlanCardProps) => {
  const classes = useStyles();

  return (
    <Box
      className={cn(classes.card, {
        [classes.cardBig]: isBig,
      })}
    >
      <Box className={classes.title}>
        <Typography component="span" variant="h6" fontWeight={500}>
          {title}
        </Typography>
        <Typography
          component="p"
          variant="body2"
          textAlign="center"
          color="info.main"
        >
          {subtitle}
        </Typography>
      </Box>
      <Box className={classes.cardContent}>
        <Box mb={6}>
          <Typography variant="body2" fontWeight={600} textAlign="center">
            {text}
          </Typography>
        </Box>

        <Box margin="0 auto">
          {points.map((feature) => (
            <CheckThickListItem key={feature} isChecked>
              {feature}
            </CheckThickListItem>
          ))}

          {additionalPoints && additionalPoints.length > 0 && (
            <>
              <Box mb={4} mt={2}>
                <Typography variant="h4" textAlign="center" fontWeight={500}>
                  +
                </Typography>
              </Box>
              <Box mt={2}>
                {additionalPoints.map((feature) => (
                  <CheckThickListItem key={feature} isChecked>
                    {feature}
                  </CheckThickListItem>
                ))}
              </Box>
            </>
          )}
        </Box>

        <Box className={classes.actionContainer}>
          <Button
            href={paymentLink}
            color="primary"
            variant="contained"
            size="large"
          >
            {buttonText}
          </Button>
        </Box>

        <Box mt={6}>
          {!!price ? (
            <Box display="flex" justifyContent="center">
              <Typography variant="h4">
                {formatCurrency2(Math.floor(price), { currencySign: 'USD' })}
              </Typography>
              <Typography variant="body1">
                {getDecimalPart(price)} per month
              </Typography>
            </Box>
          ) : (
            <Box display="flex" justifyContent="center">
              <Typography variant="h5">Free Access</Typography>
            </Box>
          )}
        </Box>

        <Grid container justifyContent="center">
          <Grid item>
            <Box mt={8}>
              <img srcSet={`${stripe} 2x`} alt="stripe" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
