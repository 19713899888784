import { useCurrentUser } from 'hooks/auth';
import { getIsLobbyTalent, getIsVerifiedTalent } from 'utils/talent';

import { AccountTypeEnum } from '@libs/graphql-types';

export const useIsCurrentTalentVerified = (): boolean | null => {
  const { data, isTalent } = useCurrentUser();

  if (!isTalent) {
    return null;
  }

  return getIsVerifiedTalent(data?.currentTalent);
};

export const useTalentAccountType = () => {
  const { data } = useCurrentUser();

  return data?.currentTalent?.account_type || AccountTypeEnum.Free;
};

export const useTalentAccountTypeCheck = (types: AccountTypeEnum[]) => {
  const currentAccountType = useTalentAccountType();
  const res = types.includes(currentAccountType);

  return res;
};

export const useIsPaidTalentAccount = () => {
  return useTalentAccountTypeCheck([
    AccountTypeEnum.Member,
    AccountTypeEnum.Matcher,
    AccountTypeEnum.MatcherComplementary,
    AccountTypeEnum.Recruiter,
  ]);
};

export const useIsPaidMemberAccount = () => {
  return useTalentAccountTypeCheck([AccountTypeEnum.Member]);
};

export const useIsPaidMatcherAccount = () => {
  const { data } = useCurrentUser();

  return (
    useTalentAccountTypeCheck([
      AccountTypeEnum.Matcher,
      AccountTypeEnum.MatcherComplementary,
    ]) || !!data?.currentTalent?.is_matcher
  );
};

export const useIsComplementaryPaidTalentAccount = () => {
  return useTalentAccountTypeCheck([AccountTypeEnum.MatcherComplementary]);
};

export const useIsLobbyTalent = () => {
  const { getData } = useCurrentUser();
  const talent = getData()?.data?.currentTalent;
  const isLobbyTalent = getIsLobbyTalent(talent);

  return isLobbyTalent;
};
