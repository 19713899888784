import { ReactComponent as CompaniesIcon } from 'assets/icons/companies.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as GroupsIcon } from 'assets/icons/groups.svg';
import { ReactComponent as InviteIcon } from 'assets/icons/invite.svg';
import { ReactComponent as PerksIcon } from 'assets/icons/perks.svg';
import { ReactComponent as ProjectsIcon } from 'assets/icons/projects.svg';
import { ReactComponent as UserProfileIcon } from 'assets/icons/user_profile.svg';
import { DRAWER_ITEM_LOBBY_TEXT } from 'components/layout/consts';
import {
  DrawerItemBadge,
  DrawerListItemProps,
} from 'components/layout/drawer/drawer-list-item';
// import { DrawerItemBadge } from 'components/layout/drawer/drawer-list-item';
import { pathManager } from 'routes/consts';

import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

export const MESSENGER_ITEM: DrawerListItemProps = {
  link: pathManager.chat.generatePath(),
  text: 'Messenger',
  Icon: ChatOutlinedIcon,
};

export const getPerksItem = ({
  isTalentVerified,
}: {
  isTalentVerified: boolean;
}): DrawerListItemProps => {
  return {
    link: pathManager.perks.generatePath(),
    text: 'Perks',
    Icon: PerksIcon,
    // disabled: isTalentVerified,
    // htmlTitle: isTalentVerified ? DRAWER_ITEM_LOBBY_TEXT : '',
  };
};

export const getDefaultTalentItems = ({
  isFreeAccount,
  isTalentVerified,
}: {
  isFreeAccount: boolean;
  isTalentVerified: boolean;
}): DrawerListItemProps[] => {
  const freeAttrs = {
    disabled: isFreeAccount,
    htmlTitle: DRAWER_ITEM_LOBBY_TEXT,
  };
  const freeItems: DrawerListItemProps[] = [
    // {
    //   link: pathManager.talent.lobby.main.generatePath(),
    //   text: isVerificationRequired ? 'Welcome 🎉' : 'Lobby',
    //   Icon: ChairOutlinedIcon,
    // },
  ];

  return [
    ...(isFreeAccount ? freeItems : []),
    {
      link: pathManager.talent.jobBoard.generatePath(),
      text: 'Job Board',
      Icon: ProjectsIcon,
      // badge: isFreeAccount ? <DrawerItemBadge text="4k" /> : undefined,
      // ...(isFreeAccount ? freeAttrs : {}),
    },
    {
      link: pathManager.talent.community.generatePath(),
      text: 'Networking',
      Icon: GroupsIcon,
      // badge: isFreeAccount ? <DrawerItemBadge text="27k" /> : undefined,
      ...(isFreeAccount ? freeAttrs : {}),
    },
    {
      link: pathManager.talent.careerHub.main.generatePath(),
      text: 'Career Hub',
      Icon: GroupsIcon,
      badge: (
        <DrawerItemBadge
          text="New"
          color="success"
          variant="filled"
          style={{ color: 'white' }}
        />
      ),
    },
    {
      link: pathManager.talent.companies.main.generatePath(),
      text: 'Communities',
      Icon: CompaniesIcon,
      // badge: (
      //   <DrawerItemBadge
      //     text="Beta"
      //     color="success"
      //     variant="filled"
      //     style={{ color: 'white' }}
      //   />
      // ),
    },
    {
      link: pathManager.talent.invite.generatePath(),
      text: 'Invite & Earn',
      Icon: InviteIcon,
      ...(!isTalentVerified
        ? { disabled: true, htmlTitle: 'Verification required' }
        : {}),
    },
    getPerksItem({ isTalentVerified }),
    {
      link: pathManager.talent.profile.generatePath(),
      text: 'My Profile',
      Icon: UserProfileIcon,
    },
  ];
};

export const COMPANY_COMMUNITY_ITEM: DrawerListItemProps = {
  link: pathManager.company.workforce.generatePath(),
  text: 'My Talent Pool',
};
export const COMPANY_JOB_BOARD_ITEM = {
  link: pathManager.company.jobBoard.generatePath(),
  text: 'My Jobs',
  Icon: ProjectsIcon,
  testName: 'company-job-board-drawer-link',
};

export const DEFAULT_ENTERPRISE_COMPANY_ITEMS: DrawerListItemProps[] = [
  {
    link: pathManager.company.dashboard.generatePath(),
    text: 'Dashboard',
    Icon: DashboardIcon,
    testName: 'company-dashboard-drawer-link',
  },
  COMPANY_COMMUNITY_ITEM,
  COMPANY_JOB_BOARD_ITEM,
  {
    link: pathManager.company.createProfile.generatePath(),
    text: 'Invite',
    Icon: InviteIcon,
  },
];

export const DEFAULT_COMPANY_ITEMS: DrawerListItemProps[] = [
  COMPANY_JOB_BOARD_ITEM,
  {
    link: pathManager.company.dashboard.generatePath(),
    text: 'Dashboard',
    Icon: DashboardIcon,
    testName: 'company-dashboard-drawer-link',
  },
  COMPANY_COMMUNITY_ITEM,
  {
    link: pathManager.company.createProfile.generatePath(),
    text: 'Invite',
    Icon: InviteIcon,
  },
];

export const NOT_ACCEPTED_TALENT_ITEMS: DrawerListItemProps[] = [
  {
    link: pathManager.talent.editProfile.generatePath(),
    text: 'Profile',
    Icon: UserProfileIcon,
  },
];
