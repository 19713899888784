import stripeImage from 'assets/images/stripe@2x.png';
import { CheckThickListItem } from 'components/check-thick-list';
import { TalentModals } from 'components/custom/talent/types';
import { PREMIUM_FEATURES } from 'consts/talents';
import React from 'react';
import {
  DefaultModalProps,
  useOpenModal,
  withLocationStateModal,
} from 'utils/modals';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { AccountTypeEnum } from '@libs/graphql-types';
import { formatCurrency } from '@libs/helpers/format';
import { DefaultModal } from '@libs/ui/components/modals';
import Typography from '@libs/ui/components/typography';

import StripePaymentButton from '../../stripe-payment-button';

interface TalentPremiumModalProps extends DefaultModalProps<true> {}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#191919',
    color: 'white',
    borderRadius: 8,
    width: 600,

    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  chip: {
    backgroundColor: '#EEEEEE',
  },
}));

const TalentPremiumModalComponent = ({
  isOpen,
  close,
}: TalentPremiumModalProps) => {
  const classes = useStyles();
  // const { data } = useGetCompaniesLandingDataQuery();

  return (
    <DefaultModal
      handleClose={close}
      open={isOpen}
      className={classes.root}
      title={
        <>
          <Typography whiteSpace="break-spaces">
            {`Upgrade to connect and collaborate
with `}
            <Typography component="span" fontWeight={700} color="primary.main">
              Europe’s #1 talent community.
            </Typography>
          </Typography>
        </>
      }
      actions={
        <Box margin="0 auto" width={320}>
          <Box mb={6}>
            <StripePaymentButton
              color="primary"
              accountType={AccountTypeEnum.Member}
            >
              Select
            </StripePaymentButton>
          </Box>

          <Box mt={6}>
            <img srcSet={`${stripeImage} 2x`} alt="Stripe" />
          </Box>
        </Box>
      }
    >
      <Box mt={6} mb={6}>
        <Typography variant="h4" component="span" fontWeight={600}>
          Member
        </Typography>
      </Box>

      <Box mt={6} mb={6}>
        <Typography style={{ textDecoration: 'underline' }}>
          What are the benefits:
        </Typography>
      </Box>

      <Box width={250} margin="0 auto">
        {PREMIUM_FEATURES.map((feature) => (
          <CheckThickListItem key={feature} isChecked>
            {feature}
          </CheckThickListItem>
        ))}
      </Box>

      <Box mt={6} display="flex" justifyContent="center">
        <Typography variant="h4">{formatCurrency(4)}</Typography>
        <Typography variant="body1">.99 per month</Typography>
      </Box>
    </DefaultModal>
  );
};

export const TalentPremiumModal = withLocationStateModal({
  id: TalentModals.LimitedAccess,
})(TalentPremiumModalComponent);

export const useOpenTalentPremiumModal = () =>
  useOpenModal(TalentModals.LimitedAccess);
