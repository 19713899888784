import { gql } from '@apollo/client';
import FULL_JOB_FRAGMENT from 'graphql/fragments/talent/talentJobFragment';

export const GET_JOB_INFO = gql`
  ${FULL_JOB_FRAGMENT}
  query GetJob($id: ID!) {
    job(id: $id) {
      ...FullJob
      stream_chat_subject_id
      job_matcher_applications {
        id
        matcher {
          id
        }
        status
      }
      matches {
        id
        created_at
        suggesting_stage
        job_suggestion {
          suggested_by_talent {
            first_name
            last_name
            first_name_abac {
              value
            }
            last_name_abac {
              value
            }
          }
          screening_questionnaire_attachment {
            title
            url
          }
          motivation_letter_attachment {
            title
            url
          }
          cv_attachment {
            title
            url
          }
          first_name
          last_name
          talent {
            id
            first_name_abac {
              value
            }
            last_name_abac {
              value
            }
            avatar {
              avatar
            }
          }
        }
        talent_invitation {
          id
          first_name
          last_name
          talent {
            id
            first_name_abac {
              value
            }
            last_name_abac {
              value
            }
            avatar {
              avatar
            }
          }
        }
      }
    }
    #    currentTalentJobApplicationByJobId(job_id: $id) {
    #      id
    #      pitch
    #      rate
    #    }
  }
`;
