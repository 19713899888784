import { Talent, User } from '@libs/graphql-types';

import { useCurrentUserAccounts } from './useCurrentUserAccounts';

export const useIsCurrentUserOmni = () => {
  const { accounts } = useCurrentUserAccounts();
  const allAccounts: (Talent | User | undefined)[] = [
    ...(accounts?.talent_accounts || []),
    ...(accounts?.company_user_accounts || []),
  ];

  return allAccounts.filter(Boolean).length > 1;
};
