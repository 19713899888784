import { useMatcherAssignmentsExceeded } from 'hooks/talents';
import React from 'react';

import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Alert, Box, Grid } from '@mui/material';

import { Job, JobMatcherApplicationStatusEnum } from '@libs/graphql-types';
import Button from '@libs/ui/components/button';
import { isUnprocessableJob } from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

import { ApplyButton } from './apply-button';
import { JobPDFPopup, useOpenJobPDFModal } from './job-pdf-popup';
import PresentCandidateButton from './present-candidate/PresentCandidateButton';

interface TitleLineProps {
  job: Job;
  seatsLeft: number;
  matcherApplicationStatus: JobMatcherApplicationStatusEnum;
}

const TitleLine = ({
  job,
  seatsLeft,
  matcherApplicationStatus,
}: TitleLineProps) => {
  const isAssignmentsExceeded = useMatcherAssignmentsExceeded();
  const isApproved = matcherApplicationStatus === 'APPROVED';
  const unprocessable = isUnprocessableJob(job);
  const needShowApplyBtn = !isApproved && !unprocessable;
  const needShowPresentCandidateBtn = isApproved;
  const noMoreSource =
    isAssignmentsExceeded && matcherApplicationStatus === 'UNKNOWN';

  const openPDFModal = useOpenJobPDFModal();

  return (
    <>
      <div>
        <Typography
          variant="overline"
          transform="uppercase"
          color="success.main"
        >
          Recruiter program
        </Typography>

        <Grid container spacing={4}>
          <Grid item>
            <Typography variant="h5">{job.name}</Typography>
          </Grid>

          <Grid container item spacing={4}>
            <Grid item>
              <Button
                onClick={() => openPDFModal()}
                endIcon={<PictureAsPdfOutlinedIcon />}
                color="info"
                variant="outlined"
              >
                DOWNLOAD ROLE DETAILS
              </Button>

              <JobPDFPopup job={job as Job} />
            </Grid>
            {needShowApplyBtn && (
              <Grid item>
                <ApplyButton
                  job={job}
                  disabled={unprocessable}
                  seatsLeft={seatsLeft}
                  matcherApplicationStatus={matcherApplicationStatus}
                />
              </Grid>
            )}
            {needShowPresentCandidateBtn && (
              <Grid item>
                <PresentCandidateButton job={job} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>

      {noMoreSource && (
        <Box mt={4}>
          <Alert color="error">
            The number of positions to which the user is assigned as a matcher
            has been exceeded
          </Alert>
        </Box>
      )}
    </>
  );
};

export default TitleLine;
