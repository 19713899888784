import { ConnectedPageLayout } from 'components/layout/page-layout';
import React from 'react';
import { pathManager } from 'routes';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { RouterButton } from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

import { CareerHubPlanCard } from './CareerHubPlanCard';

interface CareerHubPricingProps {}

const useStyles = makeStyles(() => ({
  contentWrapper: {},
  root: {},
  title: {},
}));

const CARDS_DATA = [
  {
    type: '123',
    title: 'Career Kick-start',
    subtitle: 'Monthly plan',
    text: 'Perfect for those seeking a flexible, short-term boost in their job search efforts.',
    buttonText: 'GET STARTED',
    price: 11.0,
    points: [
      'Unlimited AI-powered Resumes & Cover Letters',
      'Unlimited AI-powered Interview Prep',
      'Unlimited AI-powered Job Offer Negotiations',
      'Real-time Job & Contacts Tracker',
      'Priority Email Support ❤️',
    ],
    paymentLink:
      'careerhub.opentalent.co/billing/purchase/66daa9ccb7b6270012f3f821',
  },
  {
    type: '123',
    title: 'Career Transformation',
    buttonText: 'GET STARTED',
    subtitle: 'Get 2 months for free with Annual plan',
    text: 'An excellent choice for individuals planning a year-round, comprehensive career advancement journey.',
    isBig: true,
    points: [
      'Unlimited AI-powered Resumes & Cover Letters',
      'Unlimited AI-powered Interview Prep',
      'Unlimited AI-powered Job Offer Negotiations',
      'Real-time Job & Contacts Tracker',
      'Priority Email Support ❤️',
    ],
    paymentLink:
      'careerhub.opentalent.co/billing/purchase/66daa9994514d200132e822b',
    price: 110.0,
    // isBig: true,
    chipText: 'most popular',
  },
];

export const CareerHubPricing = (props: CareerHubPricingProps) => {
  const classes = useStyles();

  return (
    <div>
      <ConnectedPageLayout
        classes={{ contentWrapper: classes.contentWrapper }}
        documentTitle="Career Hub"
        headerProps={{ accountProps: {} }}
        drawerProps={{}}
      >
        <div className={classes.root}>
          <Box>
            <RouterButton
              variant="text"
              startIcon={<ArrowBackIcon />}
              color="info"
              to={pathManager.talent.careerHub.main.generatePath()}
            >
              Back
            </RouterButton>
          </Box>
          <Box mb={8} mt={12}>
            <Typography
              className={classes.title}
              variant="h4"
              fontWeight={400}
              textAlign="center"
              family="nohemi"
            >
              Choose the plan that best fits your role
            </Typography>
          </Box>
          <Grid
            container
            spacing={12}
            justifyContent="center"
            flexDirection="column"
          >
            <Grid item>
              <Grid container justifyContent="center" spacing={12}>
                {CARDS_DATA.map((d) => (
                  <Grid item key={d.type}>
                    <CareerHubPlanCard {...(d as any)} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </ConnectedPageLayout>
    </div>
  );
};
