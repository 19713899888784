import React from 'react';
import { pathManager } from 'routes';

import { Box, Grid } from '@mui/material';

import { RouterButton } from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

import image from './image@2x.png';

interface IntroProps {}

export const Intro = (props: IntroProps) => {
  return (
    <Box>
      <Grid container spacing={6} flexDirection="column">
        <Grid item>
          <Typography variant="h4" textAlign="center">
            Take full control of your job search
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            textAlign="center"
            style={{ maxWidth: 690, margin: '0 auto' }}
          >
            Transform your resume into a compelling story of your achievements
            to crafting personalized cover letters within seconds, preparing for
            interviews with precision, and negotiating your salary like a pro
            with Career Hub from OpenTalent.
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <RouterButton
            variant="contained"
            color="info"
            to={pathManager.talent.careerHub.pricing.generatePath()}
          >
            Supercharge your job search now
          </RouterButton>
        </Grid>
        <Grid item container justifyContent="center">
          <img srcSet={`${image} 2x`} alt="Platform screen" />
        </Grid>
      </Grid>
    </Box>
  );
};
