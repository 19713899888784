import { useCurrentUser } from 'hooks/auth';

export * from './useTalentName';
export * from './useMatcherAssignmentsExceeded';
export * from './useTalentPoolingConnections';

export const useCurrentTalentSubscription = () => {
  const { getData } = useCurrentUser();
  const subscription = getData()?.data?.currentTalentSubscription;

  return subscription;
};
