import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';

import {
  Box,
  Dialog,
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Job,
  Mutation,
  MutationCancelApplicationForJobArgs,
} from '@libs/graphql-types';
import { stopEvent } from '@libs/helpers/common';
import { formatCurrency } from '@libs/helpers/format';
import Button from '@libs/ui/components/button';
import { isUnprocessableJob } from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

import ChatWithClientButton from '../../../shared/chat-with-client-button';
import { WITHDRAW_JOB } from '../../queries';

interface JobCardFooterProps {
  onJobApply: any;
  onInvite: any;
  job: Job;
  loadJobs?: VoidFunction;
  isOpen?: boolean;
}

const useStyles = makeStyles((theme) => ({
  dialogClass: {
    padding: '40px',
    width: '500px',

    '& > .MuiDialogTitle-root, .MuiDialogContent-root': {
      padding: 0,
      overflow: 'hidden',
    },

    '& > .MuiDialogActions-root': {
      padding: 0,
    },
  },
  dialogTitle: {
    padding: 0,
    maxWidth: '325px',
    width: '100%',
    margin: '0 auto 24px auto',
    textAlign: 'center',
    lineHeight: '24px',
  },
  dialogContent: {
    padding: 0,
    width: '100%',
    marginBottom: '32px',
  },
  inputTitle: {
    textAlign: 'center',
    lineHeight: '20px',
    marginBottom: '24px',
  },
  withdrowBtn: {
    marginLeft: '8px',
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
  },
  cancelBtn: {
    display: 'none',
  },
  confirmBtn: {
    backgroundColor: theme.palette.warning.main,
    height: '42px',
  },
}));

const JobCardFooter = ({
  job,
  onJobApply,
  onInvite,
  loadJobs,
}: JobCardFooterProps) => {
  const history = useHistory();
  const classes = useStyles();
  const [withdrawReason, setWithdrawReason] = useState('');
  const [dialogOpened, setDialogOpened] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isActionsDisabled = isUnprocessableJob(job);
  const closeDialog = () => {
    setDialogOpened(false);
  };

  const [withdrawJob] = useMutation<
    Mutation,
    MutationCancelApplicationForJobArgs
  >(WITHDRAW_JOB, {
    onCompleted: () => {
      if (loadJobs) loadJobs();
      enqueueSnackbar('Application successfully withdrawn', {
        variant: 'success',
      });
      setWithdrawReason('');
      closeDialog();
    },
    onError: () => {
      enqueueSnackbar('Error while withdraw an application', {
        variant: 'error',
      });
    },
  });

  const isOldFormat = job.is_old_format;
  const onApplyClick = useCallback(() => {
    if (isOldFormat) {
      onJobApply(job);
    } else {
      history.push(pathManager.talent.jobApply.generatePath({ id: job.id }));
    }
  }, [history, isOldFormat, job, onJobApply]);

  const changeReason = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setWithdrawReason(event.target.value);
    },
    [setWithdrawReason],
  );

  const onConfirmWithdraw = () => {
    withdrawJob({ variables: { job_id: job.id, reason: withdrawReason } });
  };

  const onWithdrawClick = useCallback(async () => {
    setDialogOpened(true);
  }, [setDialogOpened]);

  return (
    <>
      <Box pt={4}>
        <Grid spacing={2} container>
          <Grid item>
            <Typography paragraph variant="caption">
              Something for you, or know someone?
            </Typography>
          </Grid>
          {!!job.finders_fee && (
            <Grid item>
              <Typography paragraph variant="caption">
                💵 Referral bonus is {formatCurrency(1000)}
              </Typography>
            </Grid>
          )}

          <Grid flexGrow={1} textAlign="end" justifyContent="flex-end" item>
            <Typography paragraph variant="caption">
              Have a question about this job?
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid onClick={stopEvent} spacing={2} component={Box} container>
        <Grid onClick={stopEvent} item>
          {job?.is_applied ? (
            <>
              <Button
                style={{ pointerEvents: 'none' }}
                variant="contained"
                color="error"
                size="small"
                data-test-id="alreadyAppliedButton"
                disabled={isActionsDisabled}
              >
                Applied
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={onWithdrawClick}
                className={classes.withdrowBtn}
                disabled={isActionsDisabled}
              >
                Withdraw
              </Button>
            </>
          ) : (
            <Button
              onClick={onApplyClick}
              variant="contained"
              color="info"
              size="small"
              data-test-id="applyJobButton"
              disabled={isActionsDisabled}
            >
              Apply to job
            </Button>
          )}
        </Grid>
        <Grid flexGrow={1} item>
          <Button
            disabled={isActionsDisabled}
            size="small"
            onClick={() => onInvite(job)}
            variant="outlined"
          >
            INVITE & EARN
          </Button>
        </Grid>
        <Grid item>
          <ChatWithClientButton job={job} disabled={isActionsDisabled} />
        </Grid>
      </Grid>
      <Dialog
        open={dialogOpened}
        onClick={stopEvent}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialogClass,
        }}
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle}>
            You are going to withdraw an application
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.dialogContent}>
            <Typography variant="subtitle2" className={classes.inputTitle}>
              Please confirm and write your reason
            </Typography>
            <TextField
              label="Why are you withdrawing the application?"
              variant="filled"
              value={withdrawReason}
              onChange={changeReason}
              multiline
              rows={5}
              fullWidth
              size="small"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.confirmBtn}
            fullWidth
            onClick={onConfirmWithdraw}
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobCardFooter;
