import { useAuth0 } from 'hooks/auth';
import { useSearchParams } from 'hooks/routing';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';

import {
  CreateCompanyUserMutationVariables,
  useCheckCompanyUserExistsByEmailLazyQuery,
  useCreateCompanyAccountAccessRequestMutation,
  useCreateCompanyForUserMutation,
} from '@libs/graphql-types';
import { isNil } from '@libs/helpers/common';
import { FormikSubmit } from '@libs/helpers/form';

import { OnboardingV4State } from './types';

export const useCheckAuth0 = () => {
  const { isAuthenticated } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    history.push(pathManager.auth.generatePath());
  }, [history, isAuthenticated]);
};

const mapFormToVariables = (
  form: OnboardingV4State,
): CreateCompanyUserMutationVariables => {
  return {
    first_name: form.general.firstName,
    last_name: form.general.lastName,
    email: form.general.email,
    is_primary: true,
    onboarding_type: 'MAIN',
  };
};

export const useSubmitAction = () => {
  const history = useHistory();
  const goToFinal = () => {
    history.push(pathManager.company.onboardingV4.final.generatePath());
  };
  const { enqueueSnackbar } = useSnackbar();
  const [check, { loading: isChecking }] =
    useCheckCompanyUserExistsByEmailLazyQuery();
  const [applyForCompany, { loading: isApplying }] =
    useCreateCompanyAccountAccessRequestMutation({
      onCompleted: () => {
        goToFinal();
      },
    });
  const [, { loading: isCompanyCreating }] = useCreateCompanyForUserMutation({
    onCompleted: () => {
      goToFinal();
    },
  });
  const onSubmit: FormikSubmit<OnboardingV4State> = useCallback(
    async (values, helpers) => {
      const variables = mapFormToVariables(values);
      const companyName =
        values.company.existingCompany?.text || values.company.newCompanyName;

      check({
        variables: { email: variables.email },
        onCompleted: (checkRes) => {
          if (checkRes?.checkCompanyUserExistsByEmail) {
            enqueueSnackbar(
              'User with such enail already exist. Please log in',
              {
                variant: 'error',
              },
            );
            return;
          }

          const refKey = localStorage.getItem(LS_KEYS.referralKey);

          applyForCompany({
            variables: {
              first_name: variables.first_name,
              last_name: variables.last_name,
              email: variables.email,
              new_company_name: companyName,
              referral_key: isNil(refKey) ? undefined : refKey,
              recruiter_employment_type: values.general.employmentType,
            },
            onCompleted: () => {
              helpers.resetForm();
              localStorage.removeItem(LS_KEYS.referralKey);
            },
          });
        },
      });
    },
    [check, enqueueSnackbar, applyForCompany],
  );

  return {
    onSubmit,
    loading: isChecking || isCompanyCreating || isApplying,
  };
};

export const LS_KEYS = {
  referralKey: 'referralKey',
};

export const useTmpVarsFromQuery = () => {
  const { referral_key } = useSearchParams();
  const [referralKey] = useState(
    localStorage.getItem(LS_KEYS.referralKey) || referral_key || '',
  );

  useEffect(() => {
    if (!isNil(referralKey)) {
      localStorage.setItem(LS_KEYS.referralKey, referralKey as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    referralKey,
  };
};
