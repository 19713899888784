import { AccordionProps } from './AccordionItem';

export const FAQ_ITEMS: Pick<AccordionProps, 'title' | 'text'>[] = [
  {
    title: 'How do I get started with Career Hub?',
    text: 'Simply sign up on our platform, follow the step-by-step onboarding process, and you’ll be set up in no time!',
  },
  {
    title: 'Is there a free trial available?',
    text: `Yes, we provide a 7-day free trial, so you can explore the tool's features without any commitment.`,
  },
  {
    title: 'What payment methods do you accept?',
    text: 'We accept all major credit cards, bank transfers, and select online payment gateways.',
  },
  {
    title: 'How long does the typical onboarding process take?',
    text: 'Most users complete the onboarding process within 30 minutes. However, the duration might vary depending on individual needs.',
  },
  {
    title: 'Is there a customer support team to help with the onboarding?',
    text: 'Absolutely! Our dedicated support team is always ready to assist you throughout the onboarding process and beyond. You can email hello@opentalent.co if you have any questions.',
  },
  {
    title: 'Can I export or delete my date from the Career Hub?',
    text: 'Absolutely! You have full control over your data. You can export or request deletion of your data at any time.',
  },
  {
    title: 'What happens if I cancel my subscription?',
    text: 'You can cancel your subscription anytime. Once canceled, you won’t be billed for the subsequent billing cycle.',
  },
];
