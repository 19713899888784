import { makeStyles } from '@mui/styles';

import { DefaultThemeType } from '@libs/ui/themes';

import { TypographyProps } from './Typography';

const useStyles = makeStyles((theme: DefaultThemeType) => {
  return {
    text: {
      textTransform: (props: TypographyProps) =>
        (props.transform || 'inherit') as any,
      fontWeight: (props: TypographyProps) =>
        (props.fontWeight || 'inherit') as any,
      cursor: (props: TypographyProps) =>
        props.pointer ? 'pointer' : 'inherit',
    },
    tertiary: {
      color: theme.palette.tertiary.main,
    },
    success: {
      color: theme.palette.success.main,
    },
    buttonLarge: {
      ...theme.typography.button,
      fontSize: 15,
      lineHeight: '26px',
    },
    buttonSmall: {
      ...theme.typography.button,
      fontSize: 14,
      lineHeight: '24px',
    },
    captionSmall: {
      ...theme.typography.caption,
      fontSize: 12,
      lineHeight: '18px',
    },
    nohemi: {
      fontFamily: 'Nohemi, Poppins, sans-serif',
    },
  };
});

export default useStyles;
