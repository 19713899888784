import img1 from './img/1@2x.png';
import img2 from './img/2@2x.png';
import img3 from './img/3@2x.png';
import img4 from './img/4@2x.png';
import img5 from './img/5@2x.png';
import { AboutSectionProps } from './section';

export const SECTIONS_CONTENT: AboutSectionProps[] = [
  {
    title: 'Create Impactful Resumes Instantly',
    subtitle: 'AI Resume Builder',
    text: 'Transform your resume into a powerful tool that captures the attention of recruiters and hiring managers worldwide. Our AI-driven tool customizes your resume for your desired roles, ensuring it shines in the competitive job market.',
    img: img1,
    isReversed: true,
    bullets: [
      'Access ATS-friendly templates for a streamlined application process',
      'Utilize AI for crafting compelling professional summaries',
      'Highlight your achievements with our AI resume writer',
    ],
  },
  {
    title: 'Personalized Cover Letters Made Simple',
    subtitle: 'AI Cover Letter Generator',
    text: 'Create cover letters that resonate with employers. Our AI generator tailors each cover letter to your application, making personalization simple and fast.',
    img: img2,
    bullets: [
      'Generate cover letters that align with your resume and the job’s requirements',
      'Choose the tone of your cover letter to match the company culture',
      'Optimize your cover letter for the perfect length: standard, short, or detailed',
    ],
  },
  {
    title: 'Ace Your Interviews with Confidence',
    subtitle: 'AI Interview Prep',
    text: 'Get customized interview preparation tailored to the specific job you’re targeting. Our AI tool provides relevant questions and strategic tips.',
    img: img3,
    isReversed: true,
    bullets: [
      'Develop interview questions and answers based on the job description',
      'Practice with sample responses to improve your technique',
      'Receive personalized advice on responding to interview questions',
    ],
  },
  {
    title: 'Organize and Engage Your Professional Network',
    subtitle: 'Contact Tracker',
    text: 'Stay connected with your professional contacts. Our tracker helps you manage and engage with your network effectively.',
    img: img4,
    bullets: [
      'Easily add and manage professional contacts',
      'Monitor interactions and follow-ups within your network',
      'Send tailored messages based on your relationship status with each contact',
    ],
  },
  {
    title: 'Track Your Job Applications with Ease',
    subtitle: 'Job Tracker',
    text: 'Keep tabs on every stage of your job application process. From initial interest to offer acceptance, our tracker keeps you informed and organized.',
    img: img5,
    isReversed: true,
    bullets: [
      'Add and track an unlimited number of job opportunities',
      'Monitor your application’s progress in a centralized hub',
      'View and manage all job applications in one place',
    ],
  },
  {
    title: 'Negotiate Like a Pro',
    subtitle: 'Salary & Benefits Negotiation',
    text: 'Get AI-powered guidance to negotiate the best salary and benefits package. Our tool provides tailored guidance based on your unique job offer and professional experience.',
    img: img1,
    bullets: [
      'Receive real time feedback on how to negotiate your salary and benefits based on your resume, job description, and offer letter',
      'Select which benefits you want to negotiate such as: paid time off (pto), remote work, salary, bonus, educational opportunities, etc.',
      'Analyze and compare multiple job offers for the best outcome',
    ],
  },
];
